// app/javascript/controllers/removals_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  addStatus() {
    this.element.disabled = true;
    document.getElementById("statusForm").style.display = "block";
  }

  close() {
    document.getElementById("statusForm").style.display = "none";
    document.getElementById("add-status").disabled = false;
  }

  createStatus(e) {
    //const id = e.target.dataset.id
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const status = document.getElementById("show_status_name").value;
    fetch(`/statuses`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ status: { status:  status } })
      }).then(response => response.json()).then(data => {
          console.log(data)
        if (data.id) {
          const sel = document.getElementById("show_status_id");
          const opt = document.createElement('option');
          opt.innerHTML = data.status;
          opt.value = data.id
          sel.appendChild(opt);
          sel.value = opt.value;
        }
        this.close();
    })
  }
}