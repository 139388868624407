import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="manage-contents"
export default class extends Controller {
  connect() {
  console.log("mdown controller loaded");
  }

  add(e) {
    e.preventDefault();
    let num = 0;
    let child = document.querySelector('div[id="content_data_blocks_datas"] > div:last-of-type');
    if (child != null)
    {
      let lastDivID = child.id.replace("content_datas_","");
      num = Number(lastDivID) + 1;
    }
    fetch("/cdata.html" /*, options */)
    .then((response) => response.text())
    .then((html) => {
        const content = html.replaceAll('NUM',num);
        var div = document.getElementById("content_data_blocks_datas");
        div.innerHTML += content;
          var tinyMDE = new TinyMDE.Editor({
            textarea: 'content_content_datas_attributes_' + num + '_copy',
            content: ''
           });
          var commandBar = new TinyMDE.CommandBar({
            element: "toolbar_" + num,
            editor: tinyMDE,
            commands: ['bold', 'italic', 'strikethrough', '|', 'code', '|', 'h2', '|', 'ul', 'ol', '|', 'blockquote', '|', 'insertLink']
          });
    })
    .catch((error) => {
        console.warn(error);
    });
  }

  remove() {
  }
}