// app/javascript/controllers/title_to_slug_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "url" ];

  convert() {
    var Title = document.getElementById("content_title").value;
    this.urlTarget.value = Title.toLowerCase().replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
  }
  removeTail() {
    var Title = document.getElementById("content_title").value;
    this.urlTarget.value = this.urlTarget.value.replace(/-$/, '');
  }

  directConversion() {
    var Title = document.getElementById("content_title").value;
    this.urlTarget.value = this.urlTarget.value.toLowerCase().replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
    this.urlTarget.value = this.urlTarget.value.replace(/-$/, '');
  }
}